import React, { Fragment, useState } from "react"
import { Row, Col } from "react-grid-system"
import PriceItem from "./priceItem"

import LargeAndSmallerWrapper from "../../components/layout/largeAndSmallerWrapper"
import LargerThanLargeWrapper from "../../components/layout/largerThanLargeWrapper"

import {
  STARTER_PLAN,
  FREE_PLAN,
  GROWTH_PLAN,
  ENTERPRISE_PLAN,
} from "./features"
import PricingSwitch from "./pricingSwitch"

const PlanButtons = props => {
  const [showAnnualPricing, setShowAnnualPricing] = useState(false)
  return (
    <Fragment>
      <LargeAndSmallerWrapper>
        <Row align="center" justify="center">
          <PricingSwitch
            showAnnualPricing={showAnnualPricing}
            setShowAnnualPricing={setShowAnnualPricing}
          />
        </Row>
        <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Col
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            <PriceItem
              signupLink={props.signupLink}
              idProp={ENTERPRISE_PLAN}
              small
              bigger
              label="Growth"
              users="Unlimited"
              price={showAnnualPricing ? "$55.99" : "$69.99"}
              priceText={
                showAnnualPricing
                  ? "Per month billed annually"
                  : "Per month billed monthly"
              }
            />
            <PriceItem
              signupLink={props.signupLink}
              idProp={GROWTH_PLAN}
              small
              label="Essentials"
              users="Unlimited"
              price={showAnnualPricing ? "$39.99" : "$49.99"}
              priceText={
                showAnnualPricing
                  ? "Per month billed annually"
                  : "Per month billed monthly"
              }
            />
            <PriceItem
              signupLink={props.signupLink}
              idProp={STARTER_PLAN}
              small
              label="Basics"
              users="Up to 14"
              price={showAnnualPricing ? "$19.99" : "$24.99"}
              priceText={
                showAnnualPricing
                  ? "Per month billed annually"
                  : "Per month billed monthly"
              }
            />
            <PriceItem
              signupLink={props.signupLink}
              idProp={FREE_PLAN}
              small
              free
              label="Free"
              users="Up to 10*"
            />
          </Col>
        </Row>
      </LargeAndSmallerWrapper>
      <LargerThanLargeWrapper>
        <Row align="center" justify="center">
          <PricingSwitch
            showAnnualPricing={showAnnualPricing}
            setShowAnnualPricing={setShowAnnualPricing}
          />
        </Row>
        <Row>
          <div
            style={{
              display: "flex",
              width: "100%",
              paddingTop: 20,
              paddingBottom: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PriceItem
              idProp={ENTERPRISE_PLAN}
              label="Growth"
              users="Unlimited"
              bigger
              price={showAnnualPricing ? "$55.99" : "$69.99"}
              priceText={
                showAnnualPricing
                  ? "Per month billed annually"
                  : "Per month billed monthly"
              }
            />
            <PriceItem
              idProp={GROWTH_PLAN}
              label="Essentials"
              users="Unlimited"
              price={showAnnualPricing ? "$39.99" : "$49.99"}
              priceText={
                showAnnualPricing
                  ? "Per month billed annually"
                  : "Per month billed monthly"
              }
            />
            <PriceItem
              idProp={STARTER_PLAN}
              label="Basics"
              users="Up to 14"
              price={showAnnualPricing ? "$19.99" : "$24.99"}
              priceText={
                showAnnualPricing
                  ? "Per month billed annually"
                  : "Per month billed monthly"
              }
            />
            <PriceItem idProp={FREE_PLAN} free label="Free" users="Up to 10*" />
          </div>
        </Row>
      </LargerThanLargeWrapper>
    </Fragment>
  )
}

export default PlanButtons
